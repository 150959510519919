<template>
  <v-card flat>
    <v-toolbar flat dense class="grey lighten-5">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
              <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-toolbar-title class="py-3 px-0">
        <span v-if="editMode">
          {{ $t("org.edit") }}
        </span>
        <span v-if="!editMode">
          {{ $t("org.new") }}
        </span>
        <v-icon v-show="editMode && changed" color="primary"
          >mdi-textbox-password</v-icon
        >
      </v-toolbar-title>
      <v-spacer />
      <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip>
      <v-divider v-if="!editMode" vertical inset class="mx-2" />
      <v-btn v-if="!editMode" small text @click="keepOpen = !keepOpen">
        <v-icon v-show="keepOpen" color="primary">mdi-pin</v-icon>
        <v-icon v-show="!keepOpen" small>mdi-pin-outline</v-icon>
        <span class="text--secondary">{{ $t("general.keepopen") }}</span>
      </v-btn>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-btn
        :disabled="isReadOnly || !valid"
        small
        text
        color="success"
        class="mx-2"
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </v-toolbar>

    <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
      <v-form ref="form" v-model="valid" class="py-5 px-5">
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              v-model="item.name"
              :counter="128"
              :rules="rules.name"
              :label="$t('general.name')"
              required
              dense
              :readonly="isReadOnly"
            ></v-text-field>

            <v-text-field
              v-model="item.acronym"
              :rules="rules.acronym"
              :label="$t('org.acronym')"
              required
              dense
              :readonly="isReadOnly"
            ></v-text-field>

            <v-select
              v-model="item.type"
              :items="types"
              :label="$t('general.type')"
              required
              dense
              :readonly="isReadOnly"
            ></v-select>
            <!-- <v-select
              v-model="item.type"
              :items="getTypesByGroup('ORGANISATION_TYPE')"
              :item-text="getLanguage === 'en' ? 'name 'nameUk'"
              item-value="type"
              :rules="rules.type"
              :label="$t('general.type')"
              required
              dense
            ></v-select> -->
          </v-col>
        </v-row>

        <v-divider />
        <v-btn
          :disabled="isReadOnly || !valid"
          :dark="!isReadOnly && valid"
          color="success"
          class="mr-4"
          tile
          depressed
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $t("general.save") }}
        </v-btn>

        <v-btn :disabled="isReadOnly" color="primary" tile depressed @click="reset">
          <v-icon>mdi-undo-variant</v-icon>
          {{$t('general.undo')}}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrgForm",
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          name: "",
          acronym: "",
          type: "INGO",
          remarks: "",
        };
      },
    },
  },
  data() {
    return {
      valid: false,
      keepOpen: false,
      types: ["INGO", "NNGO", "UN", "GOV"],
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) =>
            (!!v && v.length >= 5) || "Name must be at least than 5 characters",
          (v) =>
            (!!v && v.length <= 128) ||
            "Name must be less than or eaqual to 128 characters",
        ],
        acronym: [
          (v) => !!v || "Acronym is required",
          (v) =>
            (!!v && v.length < 15) || "Name must be less than 15 characters",
        ],
        type: [(v) => !!v || "Type is required"],
      },
    };
  },
  mounted() {
    Object.assign(this.originalState, this.item);
  },
  computed: {
    ...mapGetters("types", ["getTypesByGroup"]),
    ...mapGetters("ui", ["getLanguage"]),

    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  methods: {
    save() {
      this.$emit("save", {
        item: this.item,
        editMode: this.editMode,
        keepOpen: this.keepOpen,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
