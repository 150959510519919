<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <org-form :item="item" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import OrgForm from "./OrgForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrgEditor",
  components: { OrgForm },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters("organizations", ["getOrganizationById"]),

    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  mounted() {
    this.loadTypes();

    // in case user navigated directly to edit view load target item only
    if (this.editMode) {
      this.setLoadingState(this.$t('messages.loadingOrg'));
      const item = this.getOrganizationById(this.idParam);
      if (item) {
        this.item = item;
        this.refreshBreadcrumb();
      } else {
        this.loadOrganizations().then(() => {
          this.item = this.getOrganizationById(this.idParam);
          this.refreshBreadcrumb();
        });
      }
    } else {
      this.setLoadingState(this.$t('messages.initializingOrg'));
      this.refreshBreadcrumb();
    }
  },
  methods: {
    ...mapActions("organizations", ["loadOrganizations", "saveOrganization"]),
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("ui", ["setBreadcrumbs", "setLoadingState", "clearLoadingState"]),

    refreshBreadcrumb() {
      const updatedBreadcrumbs = this.$route.meta.breadcrumb.map((item) => {
        if (item.dynamic && this.editMode && this.item) {
          return { ...item, name: this.item.name, skipTranslate: true };
        } else if (item.dynamic) {
          return { ...item, name: "general.new", skipTranslate: false };
        }
        return item;
      });
      this.setBreadcrumbs(updatedBreadcrumbs);
      this.clearLoadingState();
    },
    save(params) {
      this.saveOrganization({ item: params.item, editMode: params.editMode });
      if (!params.keepOpen) {
        this.$router.go(-1);
      }
    },
  },
};
</script>
